<template>
  <div
    class="invite-answer"
    v-if="isClose"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="answer-content">
      <span class="close-answer" @click="closeThisWindow"
        ><span class="icon"></span
      ></span>
      <div class="answer-head">
        <span class="tip-info">您可以邀请下面用户,快速获得回答</span>
        <span class="search-input"
          ><input
            placeholder="搜索你想邀请的人"
            type="text"
            v-model="word"
            @keyup.enter="input"/>
          <i class="search-icon" @click="input"></i
        ></span>
      </div>
      <div class="answer-list">
        <div class="one-answer-man" v-for="(man, index) in list" :key="index">
          <img class="head-img" :src="man.img" alt="" @click="see(man.id)" />
          <span class="man-name" @click="see(man.id)">{{ man.name }}</span>
          <!-- <a href="javaScript:void(0)" v-if="!man.isVisited"  class="btn-invit" @click="visiteTheQuestion(man.id)">邀请回答</a>
                    <a href="javaScript:void(0)" v-if="man.isVisited"  class="btn-invit"  style="border:0">已邀请</a> -->
          <span
            data-index="index"
            @click="visiteThe(man.id, index, $event)"
            ref="isCtAuthor"
            class="btn-invit"
            >{{ man.invitation }}</span
          >
          <!-- <span v-else-if="invitation>0" class="btn-invit" style="border:0">已邀请</span> -->
        </div>
        <!-- <div v-if="list" style="width:100%;text-align:center;font-size:15px">暂无数据</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY, UPDATE, INSERT, QUERYED } from "@/services/dao.js";
import { dataFormat } from "@/js/common/index.js";
export default {
  data() {
    return {
      invitation: 0,
      loading: false, //是否显示正在加载中
      word: "",
      list: [
        // {
        //     id:1,
        //     name:"众智拖",
        //     img:"https://iknow-pic.cdn.bcebos.com/a9d3fd1f4134970ab6d03a5285cad1c8a7865d2f",
        //     isVisited:false
        // }
      ],
      user: this.$store.state.userinfo.id,
      ofSelf: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isClose: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.$on("bridge", () => {
      this.input(); //刷新
    });
  },
  created() {
    // this.searchs();
    // this.input();
  },
  methods: {
    //查看专家详情
    async see(id) {
      this.$router.push({
        path: "/answer-list",
        query: {
          id: id,
        },
      });
    },
    closeThisWindow() {
      this.word = "";
      this.$emit("closeInviteAnswer");
    },
    async input() {
      this.loading = true; //显示
      this.list = [];
      let infoUser = null;
      let user = this.$store.state.userinfo.id;
      let question = this.$route.query.id;
      let a = [];
      if (this.word == "" || this.word == null) {
        infoUser = await QUERYED(
          "post",
          "",
          'User(where: {_not: {id: {_eq: "' +
            user +
            '"}},celebrity: {_eq: "' +
            true +
            '"}}) {    id    img    name  }'
        );
      } else {
        infoUser = await QUERYED(
          "post",
          "",
          'User(where: {_not: {id: {_eq: "' +
            user +
            '"}},name: {_like: "%' +
            this.word +
            '%"}}) {    id    img    name  }'
        );
      }
      for (let i = 0; i < infoUser.data.User.length; i++) {
        a = infoUser.data.User[i];
        a.img = this.api.LoginURL.concat(infoUser.data.User[i].img);
        let data = await QUERYED(
          "post",
          "",
          "WDInvitation(where: {user: {_eq: " +
            user +
            "}, userid: {_eq: " +
            infoUser.data.User[i].id +
            "}, questioner: {_eq: " +
            question +
            "}}) {   id  }"
        );
        if (data.data.WDInvitation.length > 0) {
          ////已邀请
          a.invitation = "已邀请";
        } else {
          ////邀请回答
          a.invitation = "邀请回答";
        }
        this.list.push(a);
      }
      this.loading = false; //隐藏
      // console.log("查询："+JSON.stringify(this.list))
      console.log("长度：" + this.list.length);
      //   }
      //   else{
      //        this.list=[];
      //        this.searchs();
      //         // console.log("查询："+JSON.stringify(this.list))
      //     }
    },

    ///搜索、显示可邀请的用户
    //   async  searchs(){
    //   this.list=[];
    //   let infoUser=null;
    //   let user=this.$store.state.userinfo.id;
    //   let question=this.$route.query.id;
    //     infoUser=await QUERY("post","",'PCUsers(where: {_not: {id: {_eq: "'+user+'"}}}, limit: 20,distinct_on: id) {    id    img    name  }');
    //         for (let i = 0; i < infoUser.data.PCUsers.length; i++) {
    //     //     console.log("登陆ID"+user,"邀请ID"+this.list[i].id,"问题ID"+question)
    //     let  data=await QUERYED("post","",'WDInvitation(where: {user: {_eq: '+user+'}, userid: {_eq: '+infoUser.data.PCUsers[i].id+'}, questioner: {_eq: '+question+'}}) {   id  }');
    //     if(data.data.WDInvitation.length>0){
    //        ////已邀请
    //        this.list.push({
    //            id:infoUser.data.PCUsers[i].id,
    //            name:infoUser.data.PCUsers[i].name,
    //            img:this.api.LoginURL.concat(infoUser.data.PCUsers[i].img),
    //            invitation:"已邀请"
    //         })
    //       }
    //      else{
    //          ////邀请回答
    //          this.list.push({
    //            id:infoUser.data.PCUsers[i].id,
    //            name:infoUser.data.PCUsers[i].name,
    //            img:this.api.LoginURL.concat(infoUser.data.PCUsers[i].img),
    //            invitation:"邀请回答"
    //         })
    //      }
    //    }
    //   },
    //   实现邀请功能
    async visiteThe(id, index, e) {
      let datas = null;
      //获取登陆用户ID
      // console.log(index,"data-index: ",e.target.getAttribute('v-if'))
      let user = null;
      this.user = this.$store.state.userinfo.id;
      let question = this.$route.query.id;
      if (this.user == null || this.user == "" || this.user < 0) {
        //alert("请先登录！");
        this.$message({
          message: '请先登录！',
          type: 'warning'
        });
      } else {
        // 已邀请过的用户不得再次邀请
        if (e.target.innerText != "已邀请") {
          datas = await INSERT(
            "post",
            "",
            "   insert_WdInvitation(objects: {user: " +
              this.user +
              ", userid: " +
              id +
              ', createtime: "' +
              new Date() +
              '",questioner:' +
              question +
              "}) { affected_rows     returning {    id  } }"
          );
          if (datas.data.insert_WdInvitation.affected_rows) {
            //   消息表发送一条消息
            let modesl = await INSERT(
              "post",
              "",
              "   insert_MessageNotification(objects: {type: 1, invitationid: " +
                datas.data.insert_WdInvitation.returning[0].id +
                ', creatime: "' +
                new Date() +
                '",state:1, userid: ' +
                id +
                "}) {affected_rows  }"
            );
            if (modesl.data.insert_MessageNotification.affected_rows == 1) {
              e.target.innerText = "已邀请";
              // this.invitation=1;
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.invite-answer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.invite-answer .answer-content {
  position: relative;
  width: 756px;
  padding: 25px 40px;
  margin: 50px auto;
  background-color: #ffffff;
  height: 80%;
}
.answer-content .answer-head {
  height: 40px;
  position: relative;
  padding: 20px;
}
.answer-content .answer-list {
  height: 80%;
  padding: 20px;
  overflow-y: auto;
}
.answer-content .answer-head .tip-info {
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
}
.answer-content div .search-input {
  position: absolute;
  right: 20px;
  height: 25px;
  padding: 3px;
  border: 1px solid rgb(212, 211, 211);
  border-radius: 5%;
}
input:focus {
  outline: none;
  /* border: 1px solid #9ac1f5;
box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44); */
  /* border-width :0 0 1px 0 ; */
}
.search-input input {
  border: 0;
  width: 180px;
  height: 23px;
}
.search-icon {
  display: inline-block;
  width: 17px;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url("~@/assets/icon/search-icon.png");
  position: relative;
  top: 4px;
  cursor: pointer;
  background-size: 15px 15px;
  /* border: 1px solid red; */
}
.one-answer-man {
  padding-top: 20px;
  height: 80px;
  border-bottom: 1px solid rgb(212, 211, 211);
  position: relative;
}
.one-answer-man .head-img {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
}
.man-name:hover {
  text-decoration: underline;
}
.one-answer-man .man-name {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  vertical-align: top;
  padding-left: 20px;
  cursor: pointer;
}
.one-answer-man .btn-invit {
  padding: 10px;
  border: 1px solid rgb(64, 158, 255);
  border-radius: 6%;
  color: rgb(64, 158, 255);
  font-size: 14px;
  text-decoration: none;
  position: absolute;
  right: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.close-answer {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(78, 78, 78, 1);
  position: absolute;
  right: 10px;
  top: -10px;
  cursor: pointer;
}
.close-answer:hover {
  color: red;
}
.icon {
  width: 50px;
  height: 50px;

  /* margin-top: 200px; */
  position: relative;
  top: 20px;
  left: 30px;
  background-image: url("~@/assets/icon/close1.png");
  background-repeat: no-repeat;
  /* background-color: rebeccapurple; */
  background-position: auto;
  display: inline-block;
  background-size: 25px 25px;
}
</style>
